(function () {
'use strict';


var app = angular.module('hyresgastApp', [
  'ngResource',
  'ngRoute',
  'ngAnimate',
  'ngSanitize',
  'ngTouch',
  'ngAria',
  'ngCookies',
  'ngLocale',
  'angular-storage',
  'chart.js',
  'uiAccordion',
  'uiCollapse',
  'uiDatepickerPopup',
  'uiDropdown',
  'uiProgressbar',
  'uiTabindex',
  'uiTimepicker',
  'uiTooltip',
  'uiTypeahead',
  'uiModal',
  'dateLocal',
  'httpAuth',
  'hyUtils',
  'domainModals',
  'fileUpload',
  'flash',
  'selectAllCheckbox',
  'checkbox',
  'stringUtils',
  'modalWizard',
  'wizard',
  'timeline',
  'sorter',
  'breadcrumb',
  'uiSwitch',
  'reportMultiSelect',
  'reportParameters',
  'reportCellValue',
  'rangeInput',
  'treeView',
  'treeNode',
  'hyParticipants',
  'filePlate',
  'PortalSettings',
  'fpConsts',
]);

const parties = {
  HGF: 20,
  DELEGATE: 10,
  EXTERNAL: 0,
};

app.value('LocalApiPort', 3200); // localhost port to API during development

app.value('ObjectTypes', {
  PROPERTY:     { code: 0, name: 'property' },
  APARTMENT:    { code: 1, name: 'apartment' },
  CALL:         { code: 5, name: 'call' },
  NEGOTIATION:  { code: 6, name: 'negotiation' },
  AGREEMENT:    { code: 9, name: 'agreement' },
  PROPERTY_AVTAL: { code: 12, name: 'free' },
});

app.value('Limits', {
  InMemoryMiddleLimit: 4000,
  InMemoryExcessiveLimit: 35000,
});

app.constant('FeatureConfiguration', {
  ENABLE_MEETINGS: false,
  ENABLE_AHS: false,
});

app.constant('CommonMessages', {
  FILE_DELETE_CONFIRMATION: `Vill du verkligen radera dokumentet?
    Radera inte andras uppladdade dokument utan att informera berörd person.
    Väljer du att radera dokumentet tas den bort från databasen och kan inte återställas. 
    Radera aldrig dokument som är handlingar som styrker framställans giltighet eller viktiga förhandlingsunderlag.`,
  SIGN_WITHOUT_SCRIVE_CONFIRMATION: `Du har nu valt att avsluta denna förhandling utan e-signering, då du signerat din överenskommelse på annat vis. 
    Glöm inte bilägga den signerade överenskommelsen.
    Om ovan är korrekt kan du godkänna, annars välj avbryt och välj den andra knappen för “Börja signera”.`
});

// if feature is enabled and desiredUrl is not specified (undefined) then redirect wont take place.
function maybeBlock (featureIsEnabled, desiredUrl) {
  var path404 = '/404';
  if (featureIsEnabled) {
    return desiredUrl;
  }else{
    return path404;
  }
}

/* jshint -W101 */
app.config(["$routeProvider", 'FeatureConfiguration', function($routeProvider, FeatureConfiguration) {
  $routeProvider
    .when('/resetpassword', {
      templateUrl: 'resources/user/resetPassword/setPasswordWithToken.html',
      controller: 'SetPasswordWithTokenCtrl',
      controllerAs: 'vm'
    })
    .when('/emailreset', {
      templateUrl: 'resources/user/resetPassword/resetPassword.html',
      controller: 'SetPasswordWithTokenCtrl',
      controllerAs: 'vm'
    })
    .when('/verify-email', {
      templateUrl: 'resources/user/verify-email/verify-email.html',
      controller: 'VerifyEmailCtrl',
      controllerAs: 'vm'
    })
    .when('/negotiations', {
      title: 'Alla Förhandlingar',
      templateUrl: 'resources/negotiation/list/list.html',
      controller: 'ListNegotiationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false
    })
    .when('/negotiations/:id', {
      title: 'Förhandling',
      templateUrl: 'resources/negotiation/show/show.html',
      controller: 'ShowNegotiationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false
    })
    .when('/negotiations/:negotiationId/agreements', {
      title: 'Slutfas',
      templateUrl: 'resources/negotiation/show/agreements/show.html',
      controller: 'ShowNegotiationAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      // redirectTo is used because we want breadcrumb to redirect
      // to a specific subpage (tab) ?view=agreements,
      // whose route '/negotiations/:negotiationId/agreements' doesn't exists in client
      redirectTo: function(routeParams) {
        return '/negotiations/' + routeParams.negotiationId + '?view=agreements';
      }
    })
    .when('/negotiations/:negotiationId/agreements/:id', {
      title: 'Överenskommelse',
      templateUrl: 'resources/agreement/show/show.html',
      controller: 'ShowAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false
    })
    .when('/agreements', {
      title: 'Alla avtal',
      templateUrl: 'resources/agreement/free/list.html',
      controller: 'FreeAgreementsListCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/agreements/new', {
      title: 'Skapa avtal',
      templateUrl: 'resources/agreement/free/new/new.html',
      controller: 'NewFreeAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF]);
      }]
    })
    .when('/agreements/:id', {
      title: 'Avtal',
      templateUrl: 'resources/agreement/show/show.html',
      controller: 'ShowAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false
    })
    .when('/agreements/:id/real-estate', {
      title: 'Fastighetsbestånd',
      templateUrl: 'resources/agreement/free/properties/show.html',
      controller: 'ShowFreeAgreementPropertiesCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/agreements/:id/real-estate', {
      title: 'Fastighetsbestånd',
      templateUrl: 'resources/agreement/show/properties/show.html',
      controller: 'ShowAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/agreements/:id/rentlist/new', {
      title: 'Ladda upp hyreslista',
      templateUrl: 'resources/agreement/rentlist/new/new.html',
      controller: 'ShowAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/agreements/:id/rentlist/edit', {
      title: 'Ändra hyra',
      templateUrl: 'resources/agreement/rentlist/edit/edit.html',
      controller: 'ShowAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/agreements/:id/rentlist/view', {
      title: 'Visa',
      templateUrl: 'resources/agreement/rentlist/show.html',
      controller: 'ShowAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/blockings', {
      title: 'Slutfas',
      templateUrl: 'resources/negotiation/show/agreements/show.html',
      controller: 'ShowNegotiationAgreementCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      // redirectTo is used because we want breadcrumb to redirect
      // to a specific subpage (tab) ?view=agreements,
      // whose route '/negotiations/:negotiationId/blockings' doesn't exists in client
      redirectTo: function(routeParams) {
        return '/negotiations/' + routeParams.negotiationId + '?view=agreements';
      }
    })
    .when('/negotiations/:id/blockings/new', {
      title: 'Skapa strandning',
      templateUrl: 'resources/blocking/new/new.html',
      controller: 'NewBlockingCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/blockings/:id', {
      title: 'Strandning',
      templateUrl: 'resources/blocking/show/show.html',
      controller: 'ShowBlockingCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiations/:negotiationId/meetings', {
      title: 'Möten',
      templateUrl: 'resources/negotiation/show/meetings/show.html',
      controller: 'ShowNegotiationMeetingsCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      // redirectTo is used because we want breadcrumb to redirect
      // to a specific subpage (tab) ?view=meetings,
      // whose route '/negotiations/:negotiationId/meetings' doesn't exists in client
      redirectTo: routeParams => maybeBlock(FeatureConfiguration.ENABLE_MEETINGS, '/negotiations/' + routeParams.negotiationId + '?view=meetings')
    })
    .when('/negotiations/:negotiationId/meetings/:id', {
      title: 'Möte',
      templateUrl: 'resources/meeting/show/show.html',
      controller: 'ShowMeetingCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      // Redirect to the 404 error page if meetings are disabled. No redirection otherwise
      redirectTo: maybeBlock(FeatureConfiguration.ENABLE_MEETINGS)
    })
    .when('/negotiations/:negotiationId/properties/:id', {
      title: 'Fastighet',
      templateUrl: 'resources/negotiation/show/property/show.html',
      controller: 'ShowNegotiationPropertyCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
    })
    .when('/negotiation-calls', {
      title: 'Alla Framställningar',
      templateUrl: 'resources/negotiation-call/list/list.html',
      controller: 'ListNegotiationCallCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiation-calls/new', {
      title: 'Skapa framställan',
      templateUrl: 'resources/negotiation-call/new/new.html',
      controller: 'NewNegotiationCallCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiation/new-direct', {
      title: 'Skapa hyresförhandling',
      templateUrl: 'resources/negotiation/new-direct/new.html',
      controller: 'NewDirectNegotiationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF]);
      }]
    })
    .when('/negotiation-calls/:id', {
      title: 'Framställan',
      templateUrl: 'resources/negotiation-call/show/show.html',
      controller: 'ShowNegotiationCallCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/negotiation-calls/:id/edit', {
      title: 'Påkalla hyresförhandling',
      templateUrl: 'resources/negotiation-call/new/new.html',
      controller: 'NewNegotiationCallCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.EXTERNAL]);
      }]
    })
    .when('/negotiation-calls/:id/negotiations/newly-constructed-property', {
      title: 'Skapa förhandling',
      templateUrl: 'resources/negotiation/new/new-newly-constructed-property.html',
      controller: 'NewNegotiationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF]);
      }]
    })
    .when('/negotiation-calls/:id/negotiations/new', {
      title: 'Skapa förhandling',
      templateUrl: 'resources/negotiation/new/new.html',
      controller: 'NewNegotiationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF]);
      }]
    })
    .when('/organizations', {
      title: 'Alla fastighetsägare',
      templateUrl: 'resources/organization/list/list.html',
      controller: 'ListOrganizationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/organizations/new', {
      title: 'Lägg till ny fastighetsägare',
      templateUrl: 'resources/organization/newedit/edit.html',
      controller: 'EditOrganizationCtrl',
      controllerAs: 'vm',
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/organizations/:id', {
      title: 'Fastighetsägare',
      templateUrl: 'resources/organization/show/show.html',
      controller: 'ShowOrganizationCtrl',
      controllerAs: 'vm',
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/organizations/:id/edit', {
      title: 'Redigera',
      templateUrl: 'resources/organization/newedit/edit.html',
      controller: 'EditOrganizationCtrl',
      controllerAs: 'vm',
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/users', {
      title: 'Alla användare',
      templateUrl: 'resources/user/list/list.html',
      controller: 'ListUserCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/users/new', {
      title: 'Lägg till ny användare',
      templateUrl: 'resources/user/new/new.html',
      controller: 'NewUserCtrl',
      controllerAs: 'vm',
    })
    .when('/users/new/thank-you', {
      title: 'Bekräfta e-post',
      templateUrl: 'resources/user/new/thank-you.html',
      controller: 'NewUserCtrl',
      controllerAs: 'vm'
    })
    .when('/users/new/thank-you-invite', {
      title: 'Bekräfta e-post',
      templateUrl: 'resources/user/new/thank-you-invite.html',
      controller: 'NewUserCtrl',
      controllerAs: 'vm'
    })
    .when('/agreement/free/new/thank-you-agreement', {
      title: 'Överenskommelsen skappad',
      templateUrl: 'resources/agreement/free/thank-you.html',
    })
    .when('/users/new/error', {
      title: 'Bekräfta e-post',
      templateUrl: 'resources/user/new/error.html',
      controller: 'NewUserCtrl',
      controllerAs: 'vm'
    })
    .when('/users/:id', {
      title: 'Visa användare',
      templateUrl: 'resources/user/show/show.html',
      controller: 'ShowUserCtrl',
      controllerAs: 'vm'
    })
    .when('/users/:id/edit', {
      title: 'Redigera',
      templateUrl: 'resources/user/edit/edit.html',
      controller: 'EditUserCtrl',
      controllerAs: 'vm',
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.DELEGATE, parties.EXTERNAL]);
      }]
    })
    .when('/users/:id/negotiations', {
      title: 'Mina sidor',
      templateUrl: 'resources/user/negotiation/list/list.html',
      controller: 'ListUserNegotiationCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false
    })
    .when('/users/:id/welcome', {
      title: 'Välkommen',
      templateUrl: 'resources/user/welcome/show.html',
      controller: 'ShowUserWelcomeCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/requested-accounts', {
      title: 'Begärda konton',
      templateUrl: 'resources/requested-accounts/list.html',
      controller: 'RequestedAccountsCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/requested-accounts/organization/:id/approve', {
      title: 'Organisation',
      templateUrl: 'resources/requested-accounts/organizations/approve/approve.html',
      controller: 'ApproveOrganizationCtrl',
      controllerAs: 'vm',
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF]);
      }]
    })
    .when('/requested-accounts/user/:id/approve', {
      title: 'Användare',
      templateUrl: 'resources/requested-accounts/users/approve/approve.html',
      controller: 'ApproveUserCtrl',
      controllerAs: 'vm',
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.EXTERNAL]);
      }]
    })
    .when('/help/faq', {
      title: 'FAQ',
      templateUrl: 'resources/help/show/faq/show.html',
      controller: 'ShowHelpFaqCtrl',
      controllerAs: 'vm'
    })
    .when('/help/process', {
      title: 'Vår gemensamma process',
      templateUrl: 'resources/help/show/process/show.html',
      controller: 'ShowHelpProcessCtrl',
      controllerAs: 'vm'
    })
    .when('/help/permissions', {
      title: 'Inloggningsfel',
      templateUrl: 'resources/help/show/hgfLoginHelp.html'
    })
    .when('/reports', {
      title: 'Statistik',
      templateUrl: 'resources/reports/index.html',
      controller: 'ReportsCtrl',
      controllerAs: 'vm',
    })
    .when('/users/:id/negotiator/:partnerId/organization/:organizationId/welcome/negotiation-table/negotiator', {
      title: 'Förhandlingsbord',
      templateUrl: 'resources/user/welcome/negotiation-table/negotiator/list.html',
      controller: 'NegotiationTableNegotiatorListCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/properties', {
      title: 'Fastighetslistan',
      templateUrl: 'resources/real-estate/list/propertyList.html',
      controller: 'PropertyListCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/owners', {
      title: 'Ägare',
      templateUrl: 'resources/real-estate/list/ownersList.html',
      controller: 'OwnersListCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['UserPermissions', function(UserPermissions) {
        return UserPermissions([parties.HGF, parties.EXTERNAL]);
      }]
    })
    .when('/properties/:id', {
      title: 'Fastighet',
      templateUrl: 'resources/real-estate/show/show.html',
      controller: 'PropertyShowCtrl',
      controllerAs: 'vm',
      reloadOnSearch: false,
      resolveRedirectTo: ['$q', '$route', 'Auth', 'RealEstate', function ($q, $route, Auth, RealEstate) {
        var deferred = $q.defer();

        Auth.heartbeat(true).then(function() {
          if (!Auth.userHasPermissionForView([parties.HGF, parties.EXTERNAL])) {
            deferred.resolve('/403');
          }

          // just simple check, that id looks like GUID
          if ($route.current.params && $route.current.params.id && $route.current.params.id.length === 36) {

            RealEstate.findByExternalId({ id: $route.current.params.id })
              .$promise.then(
                function (response) {
                  if ($route.current.params.view) {
                    deferred.resolve('/properties/' + response.id + '?view=' + $route.current.params.view);
                  } else {
                    deferred.resolve('/properties/' + response.id);
                  }
                },
                // eslint-disable-next-line no-unused-vars
                function (error) {
                  deferred.reject();
                });
          } else {
            deferred.resolve();
          }
        });

        return deferred.promise;
      }]
    })

    .when('/403', {
      templateUrl: 'components/error/403.html'
    })
    .when('/404', {
      templateUrl: 'components/error/404.html'
    })

    // This one is a bit special. Redirect user on login depending on role
    .when('/', {
      resolve: {
        redirect: function(UserRedirect) {
          // jshint -W064
          return UserRedirect();
          // jshint +W064
        }
      }
    })
    /*     .otherwise('/negotiations', {
      templateUrl: 'resources/negotiation/list/list.html',
      controller: 'ListNegotiationCtrl',
      controller As: 'vm'
    });*/
    .otherwise('/404', {
      templateUrl: 'components/error/404.html'
    });
}]);
/* jshint +W101 */

app.config(function($locationProvider) {
  $locationProvider.html5Mode(true);
});

app.config(function($httpProvider) {
  //Changes to avoid IE caching ajax requests.
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }

  $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

  $httpProvider.interceptors.push(function() {
    return {
      'request': function(config) {
        if (config.data && config.data.pako) {
          //Here we should set Content-Encoding to gzip
          //But we better handle zipped body explicitly
          config.headers['PAKO!'] = 'PAKO!';
          // eslint-disable-next-line no-undef
          config.data = pako.gzip(JSON.stringify(config.data));
        }
        return config;
      }
    };
  });
  $httpProvider.interceptors.push('AuthInterceptor', 'BaseInterceptor', 'LocalhostInterceptor');
});

app.config(function($qProvider) {
  $qProvider.errorOnUnhandledRejections(false);
});

app.config(function(storeProvider) {
  storeProvider.setStore('sessionStorage');
});


})();