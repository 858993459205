(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowFreeAgreementPropertiesCtrl', ShowFreeAgreementPropertiesCtrl);

function ShowFreeAgreementPropertiesCtrl($routeParams, $location, $document, $timeout, Flash, RealEstate,
  FreeAgreement, Help, HyUtils, ErrorHandler) {
  var vm = this;
  vm.agreementId = $routeParams.id;
  vm.ready = true;
  vm.properties = [];
  vm.toggleOwnersList = {};

  vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  vm.tableColumns = [
    {
      title: 'Fastigheter',
      column: 'propertyDesignation',
    },
    {
      title: 'Fastighetsägare',
      column: 'owners',
      type: 'array',
    },
    {
      title: 'Kommun',
      column: 'municipality',
    },
    {
      title: 'LM distrikt',
      column: 'district',
    },
    {
      title: 'Antal lägenheter',
      column: 'numNegotiatedApartments',
    },
  ];

  vm.showHelpProperties = (title, bodyText) => Help.renderHelpModal(title, bodyText);
  vm.searchProperties = searchProperties;
  vm.addProperty = addProperty;

  /**
   * retrieves agreement
   * @param {number} agreementId
   */
  function getAgreement(agreementId) {
    FreeAgreement.get({
      agreementId,
      id: agreementId,
    }).$promise.then(function (response) {
      vm.agreement = response.agreement ? response.agreement : response;
      vm.readonly = vm.agreement.agreedByOwner || vm.agreement.agreedByAssociation;
    })
      .catch(new ErrorHandler('Misslyckades att hämta överenskommelse.'))
      .finally(function () {
        vm.ready = true;
      });
  }

  /**
   * retrieves properties for agreement
   * @param {Object} params
   */
  vm.getProperties = (params) => {
    params.agreementId = vm.agreementId;
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
      FreeAgreement.queryProperty(params)
        .$promise.then(response => {
          resolve(response.result);
        }).catch(new ErrorHandler('Misslyckades att hämta fastigheter.'));
    });
  };

  getAgreement(vm.agreementId);

  let getTableau = () => angular.element($document[0].querySelector("#tableau"));
  let setTableFGetData = () => {
    vm.tableFGetData = vm.getProperties;
    getTableau().scope().$broadcast('refresh');
  }

  vm.agreementPropertyActions = [
    {
      btnClass: 'btn--icon btn-fu btn--icon-bin',
      action: () => {
        vm.removeProperty(vm.selectedProperties);
      }
    }
  ];

  vm.checkboxTicked = () => $timeout(setTableFGetData, 300);
  setTableFGetData();

  vm.gotoProperty = property => {
    $location.search({});
    $location.path('/properties/' + property.id);
  };

  vm.removeProperty = properties => {
    let message = 'Är du säker?';

    HyUtils.showDefaultModal('ConfirmationModalWithMessageCtrl',
      'components/modal/confirmation/confirmationModalWithMessage.html',
      // eslint-disable-next-line no-unused-vars
      { message }, result => {
        FreeAgreement.deleteProperties({
          agreementId: vm.agreementId,
          propertyIds: properties.map(x => x.id),
        // eslint-disable-next-line no-unused-vars
        }).$promise.then(response => {
          getTableau().scope().$broadcast('refresh');
        });
      }, null, null, { size: 'xsm' });
  };

  vm.saveProperties = () => {
    vm.saving = true;
    FreeAgreement.mergeProperties({
      agreementId: vm.agreementId,
      properties: vm.properties,
    // eslint-disable-next-line no-unused-vars
    }).$promise.then(result => {
      Flash.set('Avtal har sparats', 'success');
      getTableau().scope().$broadcast('refresh');
      vm.editPropertiesMode = false;
    }).catch(new ErrorHandler('Misslyckades att spara fastigheter.'))
      .finally(function() {
        vm.saving = false;
      });
  }

  function searchProperties() {
    if (vm.inputTextComplex) {
      vm.toggleMenu = false;
      vm.searching = true;
      return RealEstate.querySimple({ 'search[complex]': vm.inputTextComplex }, {})
        .$promise.then(function(response) {
          vm.propertiesTypeahead = response.rows;
          if (vm.propertiesTypeahead.length > 0) {
            vm.toggleMenu = true;
          }
        })
        .catch(new ErrorHandler('Fel'))
        .finally(function() {
          vm.searching = false;
        });
    }
  }

  function addProperty(property) {
    var foundProperty = vm.properties.filter(function(item) {
      return item.id === property.id;
    });

    // look through all properties to check if they had been added before or not
    if (foundProperty.length === 0) {
      vm.properties.push(property);
    }
  }

}
})();